<template>
	<div class="transportManage">
		<div class="transportManage-container"><router-view></router-view></div>
		<div class="transportManage-tabbar">
			<van-tabbar v-model="active" @change="onChange">
				<van-tabbar-item v-for="(item, index) in tabbar" :key="index" :name="item.path" :icon="item.icon">{{ item.name }}</van-tabbar-item>
			</van-tabbar>
		</div>
	</div>
</template>

<script>
export default {
	name: '',
	components: {},
	data() {
		return {
			active: '/transportManage/workOrder',
			tabbar: [
				{
					name: '工单',
					path: '/transportManage/workOrder',
					icon: 'send-gift'
				},
				{
					name: '转运',
					path: '/transportManage/transport',
					icon: 'cart'
				},
				{
					name: '我的',
					path: '',
					icon: 'friends'
				}
			]
		};
	},
	watch: {
		'$route.path': {
			handler() {
				const result = this.tabbar.filter(item => {
					return this.$route.path.includes(item.path);
				});
				if (result.length) {
					this.active = result[0].path;
				}
			},
			deep: true,
			immediate: true
		}
	},
	created() {},
	mounted() {},
	methods: {
		onChange(e) {
			if (e) this.$router.push(e);
		}
	}
};
</script>

<style lang="scss" scoped>
.transportManage {
	width: 100%;
	height: 100%;
}
$color-primary: #3e73fb;
.transportManage-container {
	width: 100%;
	height: calc(100% - 50px);
}
</style>
